import { graphql } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Subscribe from '../components/subscribe'
import Layout from "../layouts/layout"
import { dateToLongString } from '../utilities/helpers'

interface BlogPostProps {
    data: {
        contentfulArticle: {
            title: string,
            date?: Date,
            image: {
                url: string,
                title: string
            },
            body: {
                raw: string
            },
            tags: Tag[]
        },
    }
    pageContext: {
        slug: string,
        tagsRecord: Record<string, Tag>
    }
}

interface IArticle {
    id: string,
    title: string,
    slug: string,
    date?: Date,
    short_description: ShortDesc,
    tags: Tag[]
}

interface ShortDesc {
    short_description: String
}

interface Tag {
    id: string,
    slug: string,
    tagName: string,
    articles?: IArticle[]
}

const BlogPost = ({ data, pageContext }: BlogPostProps) => {
    const tagRels = pageContext.tagsRecord
    const article = data.contentfulArticle
    const tag = article.tags ? tagRels[article.tags[0].slug] : null

    return(
        <Layout>
            <div className="blog-post">
                <Container>
                    <Row className="py-5">
                        <Col>
                            {article.image ?
                                <img src={article.image.url.toString()} className="w-100" alt={article.image.title.toString()} />
                                : null}
                        </Col>
                    </Row>

                    <Row className="mb-5">
                        <Col size="12" lg="9">
                            <div className="pb-4">
                                <h1 className="text-primary">{article.title}</h1>
                                {article.date ?
                                    <p><i>{dateToLongString(article.date)}</i></p>
                                    : null}
                            </div>

                            <div className="blog-post--body">
                                {   // @ts-expect-error: Rich text displays correctly even though it complains there's no 'references' field
                                    article.body && renderRichText(article.body)
                                }
                            </div>
                        </Col>

                        <Col size="12" lg="3">
                            {article.tags ?
                                <div>
                                    <h3 className="text-primary fw-bold">Related Articles</h3>
                                    <ul className="topics">
                                        {tag?.articles?.map((article, index) => 
                                            <li key={`related-article-${index}`}>
                                                <a href={`/insights/${article.slug}.html`}>{article.title}</a>
                                            </li>
                                            )}
                                    </ul>
                                </div>
                                : null}

                            <Subscribe />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query BlogPostQuery($slug: String!) {
        contentfulArticle(slug: {eq: $slug}) {
            title
            date
            body {
                raw
            }
            image {
                url
                title
            }
            tags {
                slug
                tagName
            }
        }
    }  
`

export default BlogPost